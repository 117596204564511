import React from 'react';
import PropTypes from 'prop-types';

const Bin = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Manage-Account"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Manage-Account-Edit_Desktop"
        transform="translate(-1150.000000, -530.000000)"
      >
        <g id="Bin" transform="translate(1150.000000, 530.000000)">
          <path
            d="M2.46318,44.91792 C2.46318,46.61712 3.846,47.99994 5.5452,47.99994 L29.4162,47.99994 C31.41306,47.99994 33.03966,46.3734 33.03966,44.37648 L33.037314,7.30308 L35.500614,7.30308 L35.500614,5.30388 L25.924014,5.30388 L25.92636,2.25468 C25.92636,1.01016 24.91386,-3.90798505e-14 23.67168,-3.90798505e-14 L11.83128,-3.90798505e-14 C10.5891,-3.90798505e-14 9.5766,1.01016 9.5766,2.25468 L9.5766,5.30388 L2.45137244e-13,5.30388 L2.45137244e-13,7.30308 L2.4633,7.30308 L2.46318,44.91792 Z M11.57778,2.25492 C11.57778,2.114298 11.69028,1.999452 11.830902,1.999452 L23.671302,1.999452 C23.811924,1.999452 23.92677,2.114298 23.92677,2.25492 L23.92677,5.30412 L11.57757,5.30412 L11.57778,2.25492 Z M9.57624,7.30338 L31.0358456,7.30338 L31.0358456,44.37678 C31.038186,45.2721 30.3093,46.00098 29.41632,46.00098 L5.54532,46.00098 C4.950006,46.00098 4.46484,45.515826 4.46484,44.9205 L4.46484,7.30338 L9.57624,7.30338 Z"
            id="Fill-1"
            fill="#285AFF"
          />
          <polygon
            id="Fill-2"
            fill="#40A499"
            points="8.58498 11.76132 10.58418 11.76132 10.58418 41.54112 8.58498 41.54112"
          />
          <polygon
            id="Fill-3"
            fill="#40A499"
            points="14.02938 11.76132 16.02858 11.76132 16.02858 41.54112 14.02938 41.54112"
          />
          <polygon
            id="Fill-4"
            fill="#40A499"
            points="19.47378 11.76132 21.47298 11.76132 21.47298 41.54112 19.47378 41.54112"
          />
          <polygon
            id="Fill-5"
            fill="#40A499"
            points="24.91578 11.76132 26.91498 11.76132 26.91498 41.54112 24.91578 41.54112"
          />
        </g>
      </g>
    </g>
  </svg>
);

Bin.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Bin.defaultProps = {
  width: '36px',
  height: '48px',
};

export default Bin;
