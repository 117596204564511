import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';
import { auth } from '../services';

const widgetMenuQuery = gql`
  query WidgetFragmentAppMenu(
    $variant: String!
    $appParameters: ParametersAppMenu
    $userParameters: ParametersUserMenu
  ) {
    widgetFragmentUserMenu(variant: $variant, parameters: $userParameters) {
      html
    }
    widgetFragmentAppMenu(variant: $variant, parameters: $appParameters) {
      html
    }
  }
`;

const useAppMenu = ({ user, loading }) => {
  const { data: widgetMenusData, loading: loadingMenu } = useQuery(
    widgetMenuQuery,
    {
      variables: {
        variant: 'day',
        appParameters: {
          size: '100%',
          colorNormal1: 'black',
          colorNormal2: 'white',
          colorActive2: 'white',
        },
        userParameters: {
          size: '100%',
          dataLayerName: 'PageDataLayer',
          colorNormal1: 'black',
          colorNormal2: 'white',
          colorActive2: 'white',
        },
      },
    }
  );

  const { appMenuHtml, userMenuHtml } = useMemo(() => {
    const { widgetFragmentAppMenu, widgetFragmentUserMenu } =
      widgetMenusData || {};
    return {
      userMenuHtml: widgetFragmentUserMenu ? widgetFragmentUserMenu.html : '',
      appMenuHtml: widgetFragmentAppMenu ? widgetFragmentAppMenu.html : '',
    };
  }, [widgetMenusData]);

  const intervalRef = useRef(0);

  useEffect(() => {
    if ((!userMenuHtml && !appMenuHtml) || loading) {
      return;
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (window.lsWidget && window.lsWidget.fragments) {
        clearInterval(intervalRef.current);
        intervalRef.current = 0;
        window.lsWidget.fragments.common.auth.setAuth({ auth, user });
      }
    }, 100);
  }, [userMenuHtml, appMenuHtml, loading, user]);

  return { appMenuHtml, userMenuHtml, loadingMenu };
};

export default useAppMenu;
