import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import { AppContext } from '../../context';

const Panel = ({ children, ...props }) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <Container
      borderRadius="11px"
      border="1px solid"
      borderColor="border300"
      backgroundColor="background100"
      padding={mobile ? '20px' : '35px'}
      margin="10px"
      {...props}
    >
      {children}
    </Container>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
};

Panel.defaultProps = {
  children: null,
};

export default Panel;
