import React from 'react';
import PropTypes from 'prop-types';

const AvatarDefault = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 300 300"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="-45.2604167%"
        x2="50%"
        y2="100%"
        id="AvatarDefault-linearGradient-1"
      >
        <stop stopColor="#98B0FD" offset="0%" />
        <stop stopColor="#2D60FB" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-45.2604167%"
        x2="50%"
        y2="100%"
        id="AvatarDefault-linearGradient-4"
      >
        <stop stopColor="#EAEFFF" offset="0%" />
        <stop stopColor="#97AEFF" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-120.000000, -290.000000)">
        <g transform="translate(120.000000, 290.000000)">
          <mask id="AvatarDefault-mask-3" fill="white">
            <circle id="AvatarDefault-path-2" cx="150" cy="150" r="150" />
          </mask>
          <circle
            fill="url(#AvatarDefault-linearGradient-1)"
            id="AvatarDefault-path-2"
            cx="150"
            cy="150"
            r="150"
          />
          <path
            d="M150,-1.98019802 C66.1937765,-1.98019802 -1.98019802,66.1937765 -1.98019802,150 C-1.98019802,233.806223 66.1937765,301.980198 150,301.980198 C233.806223,301.980198 301.980198,233.806223 301.980198,150 C301.980198,66.1937765 233.806223,-1.98019802 150,-1.98019802 Z M150,52.2984441 C179.853427,52.2984441 204.278642,76.7236594 204.278642,106.577086 C204.278642,136.430513 179.853427,160.855728 150,160.855728 C120.146573,160.855728 95.7213579,136.430513 95.7213579,106.577086 C95.7213579,76.7236594 120.146573,52.2984441 150,52.2984441 Z M106.577086,182.567185 L193.422914,182.567185 C223.058879,182.567185 247.267327,206.572067 247.65987,236.126744 C223.804363,263.157334 188.983355,280.268741 150,280.268741 C111.016645,280.268741 76.194942,263.157334 52.3401301,236.126744 C52.7336459,206.572067 76.9418161,182.567185 106.577086,182.567185 Z"
            fill="url(#AvatarDefault-linearGradient-4)"
            mask="url(#AvatarDefault-mask-3)"
          />
        </g>
      </g>
    </g>
  </svg>
);

AvatarDefault.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AvatarDefault.defaultProps = {
  width: '100%',
  height: '100%',
};

export default AvatarDefault;
