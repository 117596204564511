import { gql, useQuery } from '@apollo/client';

export const userQuery = gql`
  {
    currentUser {
      id
      accountId
      displayName
      avatar
      suburbId
      userRoles {
        edges {
          node {
            roleName
          }
        }
      }
      profileRoles(first: 100) {
        edges {
          node {
            profile {
              id
              displayName
              package {
                id
                name
              }
            }
          }
        }
      }
      awsAccountRoles(first: 100) {
        edges {
          node {
            awsAccount {
              profiles {
                edges {
                  node {
                    id
                    displayName
                    package {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      account {
        firstName
        lastName
        phoneNumber
        email
        isEmailUpdatable
        isPasswordUpdatable
      }
      suburb {
        name
        postcode
        state
      }
    }
  }
`;

const useUser = () => {
  const { data: { currentUser } = {}, loading, refetch } = useQuery(userQuery, {
    errorPolicy: 'all',
  });

  const { userRoles = { edges: [] } } = currentUser || {};

  const inRole = role =>
    userRoles.edges.some(({ node }) => node.roleName === role);

  const isStaff = inRole('staff');
  const isPersoniv = inRole('personiv');

  return {
    user: currentUser && { ...currentUser, isStaff, isPersoniv },
    loading,
    refetch,
  };
};

export default useUser;
