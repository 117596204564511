import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useBeforeunload } from 'react-beforeunload';
import useForm from 'ls-common-client/src/hooks/useForm';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import Validator from 'ls-common-client/src/components/Validator';
import Icon from 'ls-common-client/src/components/Icon';
import Container from 'ls-common-client/src/components/Container';
import Button from 'ls-common-client/src/components/Button';
import { H1, Paragraph } from '../../common';
import { phoneE164 } from '../../../lib';
import { AppContext } from '../../../context';
import { userQuery } from '../../../hooks/useUser';

const { fromPhoneE164, toPhoneE164 } = phoneE164;

const updateAccountMutation = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

const updateEmailMutation = gql`
  mutation updateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      account {
        email
      }
    }
  }
`;

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Account = ({ setShowSuccess }) => {
  const {
    user: {
      user: { account, id },
    },
    handleError,
  } = useContext(AppContext.Context);

  const inputRefs = {
    firstName: useRef(),
    lastName: useRef(),
    phoneNumber: useRef(),
    email: useRef(),
  };

  const { firstName, lastName, phoneNumber, email, isEmailUpdatable } = account;

  const [mutateAccount] = useMutation(updateAccountMutation, {
    onError: handleError,
    update: (cache, { data: { updateAccount } }) => {
      const currentUserData = cache.readQuery({ query: userQuery });
      cache.writeQuery({
        query: userQuery,
        data: {
          currentUser: {
            ...currentUserData.currentUser,
            account: {
              ...currentUserData.currentUser.account,
              ...updateAccount.account,
            },
          },
        },
      });
    },
  });

  const [mutateEmail] = useMutation(updateEmailMutation, {
    onError: handleError,
  });

  const {
    changed,
    values,
    setValue,
    validation: { patternMismatch, typeMismatch, valueMissing },
    valid,
    onSubmit,
    loading,
  } = useForm({
    defaults: {
      firstName: firstName || '',
      lastName: lastName || '',
      phoneNumber: fromPhoneE164(phoneNumber),
      email: email || '',
    },
    onSubmit: async inputVals => {
      const vals = {
        ...inputVals,
        phoneNumber: toPhoneE164(inputVals.phoneNumber),
      };

      const hasChange = name =>
        account[name] !== vals[name] && vals[name]
          ? { [name]: vals[name] }
          : null;

      if (hasChange('email')) {
        await mutateEmail({
          variables: {
            input: {
              userId: id,
              email: vals.email,
            },
          },
        });
      }

      if (
        hasChange('firstName') ||
        hasChange('lastName') ||
        hasChange('phoneNumber')
      ) {
        await mutateAccount({
          variables: {
            input: {
              userId: id,
              ...(hasChange('firstName') || {}),
              ...(hasChange('lastName') || {}),
              ...(hasChange('phoneNumber') || {}),
            },
          },
        });
      }

      setShowSuccess(true);
    },
  });

  useBeforeunload(e => {
    return changed() ? e.preventDefault() : null;
  });

  const onClear = name => {
    setValue({
      value: '',
      name,
      validity: { valueMissing: !!account[name] },
    });
    inputRefs[name].current.focus();
  };

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <H1>Account Settings</H1>
        <Paragraph>
          These details will be used to help speed up filling out forms on
          Localsearch!
        </Paragraph>
        <Container marginBottom="20px">
          <FancyInput
            name="firstName"
            onChange={setValue}
            onClear={() => onClear('firstName')}
            required={!!firstName}
            label="First Name"
            icon={
              <Icon
                iconSize="27px"
                width="34px"
                className="ls-icon icon-categoryorganisations"
              />
            }
            value={values.firstName}
            pattern="^[a-zA-Z-',]+(s{0,1}[a-zA-Z-', ])*$"
            ref={inputRefs.firstName}
          />
          {patternMismatch('firstName') && (
            <Validator>
              Oops, looks like you entered a special character and/or a number.{' '}
              {patternMismatch('firstName') ? 't ' : 'f '}
            </Validator>
          )}
          {valueMissing('firstName') && (
            <Validator>Let us know what we can call you.</Validator>
          )}
        </Container>

        <Container marginBottom="20px">
          <FancyInput
            name="lastName"
            onChange={setValue}
            onClear={() => onClear('lastName')}
            required={!!lastName}
            label="Last Name"
            icon={
              <Icon
                iconSize="27px"
                width="34px"
                className="ls-icon icon-categoryorganisations"
              />
            }
            value={values.lastName}
            pattern="^[a-zA-Z-',]+(s{0,1}[a-zA-Z-', ])*$"
            ref={inputRefs.lastName}
          />
          {patternMismatch('lastName') && (
            <Validator>
              Oops, looks like you entered a special character and/or a number.
            </Validator>
          )}
          {valueMissing('lastName') && (
            <Validator>Let us know what we can call you.</Validator>
          )}
        </Container>

        <Container marginBottom="20px">
          <FancyInput
            name="phoneNumber"
            onChange={setValue}
            onClear={() => onClear('phoneNumber')}
            required={!!phoneNumber}
            label="Phone Number"
            icon={
              <Icon
                iconSize="27px"
                width="34px"
                className="ls-icon icon-generalcalllarge"
              />
            }
            value={values.phoneNumber}
            pattern="^[0-9 +]*$"
            ref={inputRefs.phoneNumber}
          />
          {(patternMismatch('phoneNumber') || valueMissing('phoneNumber')) && (
            <Validator>Oops! That isn&apos;t a valid number.</Validator>
          )}
        </Container>

        {isEmailUpdatable && (
          <Container marginBottom="20px">
            <FancyInput
              name="email"
              type="email"
              onChange={setValue}
              onClear={() => onClear('email')}
              required={!!email}
              label="Email Address"
              icon={
                <Icon
                  iconSize="27px"
                  paddingLeft="5px"
                  width="34px"
                  marginBottom="5px"
                  className="ls-icon icon-categorypostoffices"
                />
              }
              value={values.email}
              ref={inputRefs.email}
            />
            {typeMismatch('email') ||
              (valueMissing('email') && (
                <Validator>
                  Hmm... That doesn&apos;t look right. Please check it and enter
                  a valid email address.
                </Validator>
              ))}
          </Container>
        )}

        <Container display="flex" justifyContent="center" marginTop="auto">
          <Button
            type="submit"
            disabled={!changed() || !valid()}
            maxWidth="215px"
            rounded
            primary
            loading={loading}
          >
            Save Changes
          </Button>
        </Container>
      </StyledForm>
    </>
  );
};

Account.propTypes = {
  setShowSuccess: PropTypes.func,
};

Account.defaultProps = {
  setShowSuccess: () => {},
};

export default Account;
