import React, { useContext } from 'react';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import Animation from 'ls-common-client/src/components/Animation';
import { AppContext } from '../../context';
import trophy from '../../animations/trophy.json';

const SuccessNotification = props => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  return (
    <NotificationSlider
      mode={!mobile ? 'desktop' : 'mobile'}
      icon={<Animation width={mobile ? '200px' : '100%'} data={trophy} />}
      heading="Your changes have been saved!"
      text="You can head back to account settings any time you need to update your details."
      {...props}
    />
  );
};

export default SuccessNotification;
