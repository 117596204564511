import React, { useContext } from 'react';
import MoreButton from 'ls-common-client/src/components/MoreButton';
import Container from 'ls-common-client/src/components/Container';
import { Shield, Bin } from '../../../svgs';
import { Panel, Paragraph, H2 } from '../../common';
import { AppContext } from '../../../context';

const Help = () => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <>
      <Panel flex="1" padding={mobile ? '20px' : '30px'}>
        <Container marginBottom="15px">
          <Shield />
        </Container>
        <H2>What info is shared with others?</H2>
        <Paragraph>
          These details will be used to help speed up filling out forms on
          Localsearch!
        </Paragraph>
        <MoreButton
          target="_blank"
          href="https://business.localsearch.com.au/legal"
        >
          View Full T &amp; C&apos;s
        </MoreButton>
      </Panel>
      <Panel flex="1" padding={mobile ? '20px' : '30px'}>
        <Container marginBottom="15px">
          <Bin />
        </Container>
        <H2>How to manage your account.</H2>
        <Paragraph>
          Explore how to pause your user-exclusive features.
        </Paragraph>
        <MoreButton to="/how-to-manage">Learn more</MoreButton>
      </Panel>
    </>
  );
};

export default Help;
