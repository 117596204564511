import React, { useEffect, useState } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import ReactDom from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { hot } from 'react-hot-loader/root';
import { HelmetProvider } from 'react-helmet-async';
import App from './components/App';
import client from './services/client';
import { logger, auth } from './services';
import config from './config';

const { init } = auth;

logger.info(config);

const Root = () => {
  const navigate = useNavigate();
  const [hasToken, setHasToken] = useState();

  useEffect(() => {
    (async () => {
      const authToken = await init({
        loginOptions: {
          screen_hint:
            window.location.pathname.includes('/profileInvitations') ||
            window.location.pathname.includes('/awsAccountInvitations')
              ? 'signup'
              : undefined,
        },
        onRedirect: url => {
          navigate(url);
        },
      });
      setHasToken(!!authToken);
    })();
  }, []);

  if (!hasToken) return null;

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ApolloProvider>
  );
};

const render = async () => {
  ReactDom.render(
    <BrowserRouter>
      <Root />
    </BrowserRouter>,
    document.getElementById('root')
  );
};

render();

export default hot(render);
