import React from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';

const CloseButton = ({ iconSize, onClick, ...rest }) => {
  return (
    <EmptyButton onClick={onClick} borderRadius="50%" display="flex" {...rest}>
      <Icon iconSize="medium" className="ls-icon icon-generalxlarge" />
    </EmptyButton>
  );
};

CloseButton.propTypes = {
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  iconSize: '16px',
  color: '#4a4a4a',
  hoverColor: '#4a4a4a',
  onClick: () => {},
};

export default CloseButton;
