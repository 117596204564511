import React from 'react';
import PropTypes from 'prop-types';

const Shield = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 49"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Manage-Account"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Manage-Account-Edit_Desktop"
        transform="translate(-1155.000000, -216.000000)"
      >
        <g id="Shield" transform="translate(1155.290448, 216.231885)">
          <path
            d="M38.1114725,5.54984892 L19.6631525,0.04038012 C19.4850293,-0.01346004 19.2937781,-0.01346004 19.1156645,0.04038012 L0.67310448,5.54984892 C0.27373008,5.66864892 1.7408297e-13,6.03246204 1.7408297e-13,6.44640876 L1.7408297e-13,24.8048104 C1.7408297e-13,39.6101416 10.2336005,44.6201756 17.9270405,47.1002433 L19.0970405,47.480774 C19.2864149,47.542032 19.4926661,47.542032 19.6820645,47.480774 L20.8520645,47.1002433 C28.5507845,44.6072968 38.7791141,39.6234472 38.7791141,24.8048104 L38.7791141,6.44640876 C38.7809765,6.03432007 38.5091045,5.6705022 38.1116165,5.54984892 L38.1114725,5.54984892 Z M36.8908325,24.8049529 C36.8908325,31.3166185 35.1658085,40.5198169 20.2669925,45.3240894 L19.3857605,45.6043814 L18.5045285,45.3240894 C11.3741285,43.0000758 1.88836848,38.3947225 1.88836848,24.8049529 L1.88836848,7.13891772 L19.3934885,1.91742012 L36.8986085,7.13891772 L36.8908325,24.8049529 Z"
            id="Fill-1"
            fill="#40A499"
          />
          <path
            d="M11.5501925,37.2784777 C7.63521648,34.3623179 5.80891248,30.4232425 5.80891248,24.8154073 L5.80891248,9.75584412 C5.80891248,9.23982444 5.38703568,8.82027036 4.86388848,8.82027036 C4.34265648,8.82027036 3.91886448,9.23792839 3.91886448,9.75584412 L3.91886448,24.8044777 C3.91886448,31.0357753 5.98324848,35.4779449 10.4084645,38.7744073 C10.8247157,39.0843993 11.4172325,39.0008686 11.7303365,38.5869267 C12.0434597,38.174838 11.9590853,37.5882463 11.5409621,37.2782734 L11.5501925,37.2784777 Z"
            id="Fill-2"
            fill="#40A499"
          />
          <path
            d="M18.4857125,4.65134076 C18.3338357,5.14512108 18.6169637,5.6667006 19.1157125,5.81705388 L33.4033925,10.0790276 L33.4033925,24.8026244 C33.4033925,30.5050244 31.8040325,37.5641204 19.3729925,41.6275556 C18.8611205,41.5254637 18.3642245,41.854017 18.2611205,42.3607892 C18.1673717,42.8285652 18.4411205,43.2907542 18.9004805,43.4355477 L19.0954805,43.498659 C19.2848549,43.559917 19.4911061,43.559917 19.6805045,43.498659 L19.8755045,43.4355477 C27.0659045,41.0929556 35.2993445,36.8868179 35.2993445,24.8043827 L35.2993445,9.38841473 C35.2955957,8.97447276 35.0199701,8.61250817 34.6187045,8.49741473 L19.6638245,4.02944177 C19.1650565,3.87908374 18.6382085,4.15752242 18.4863365,4.65128849 L18.4857125,4.65134076 Z"
            id="Fill-3"
            fill="#40A499"
          />
          <path
            d="M28.2431525,18.4600825 C27.8737781,18.0962599 27.2775365,18.0962599 26.9081765,18.4600825 L16.5094565,28.7548153 L11.8763525,24.1680424 C11.4957269,23.8153537 10.8975845,23.837631 10.5413765,24.2144504 C10.2038789,24.5727084 10.2038789,25.1295906 10.5413765,25.4896971 L15.8420165,30.7373307 C16.2113909,31.1011533 16.8076325,31.1011533 17.1769925,30.7373307 L28.2433925,19.7872971 C28.6127669,19.4234745 28.6165157,18.8313373 28.2490181,18.4656709 C28.2452693,18.4638128 28.2452693,18.4619596 28.2433925,18.4601015 L28.2431525,18.4600825 Z"
            id="Fill-4"
            fill="#285AFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

Shield.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Shield.defaultProps = {
  width: '40px',
  height: '49px',
};

export default Shield;
