import React, { useEffect, useContext, useMemo, useState } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Button from 'ls-common-client/src/components/Button';
import Anchor from 'ls-common-client/src/components/Anchor';
import Dialog from 'ls-common-client/src/components/Dialog';
import { Panel, H2, BackBar, Paragraph } from '../common';
import { Face, Wave } from '../../svgs';
import { auth } from '../../services';
import { AppContext } from '../../context';
import CloseButton from '../common/CloseButton';

const HowToManage = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    media: { mobile } = {},
    user: {
      user: {
        id,
        accountId,
        account: { email },
        profileRoles,
        awsAccountRoles,
      },
    },
  } = useContext(AppContext.Context);

  const accessProfiles = useMemo(() => {
    const profileRoleProfiles = profileRoles.edges.map(
      ({ node }) => node.profile
    );

    const awsAccountRoleProfiles = [];

    awsAccountRoles.edges.forEach(({ node }) => {
      const { awsAccount } = node;
      const { profiles } = awsAccount || {};
      const { edges = [] } = profiles || {};

      awsAccountRoleProfiles.push(...edges.map(profile => profile.node));
    });

    return [...profileRoleProfiles];
  }, [profileRoles, awsAccountRoles]);

  const emailHref = useMemo(() => {
    const profilesText = accessProfiles.reduce(
      (txt, { id: profileId, displayName }) =>
        `${txt}\n\t${profileId}\t ${displayName}`,
      ''
    );
    const subject = encodeURIComponent('Request to delete Localsearch user');
    const body = encodeURIComponent(`
      Please delete my Localsearch user.

      id: ${id}
      account id: ${accountId}
      email: ${email}

      Profile${accessProfiles.length > 1 ? 's' : ''}: ${profilesText || 'None'}
    `);
    return `mailto:products@localsearch.com.au?subject=${subject}&body=${body}`;
  }, [id, accountId, email, accessProfiles]);

  const paidPackage = useMemo(
    () => accessProfiles.some(({ package: pricePackage }) => !!pricePackage),
    [accessProfiles]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BackBar />
      <Container display="flex" padding="0 10px" flexWrap="wrap">
        <Panel flex="1" minWidth="300px">
          <Container marginBottom="15px">
            <Face />
          </Container>
          <H2>What happens if you delete your account?</H2>
          <Paragraph marginBottom="20px">
            When you delete your user profile, you also delete all your
            activity, including access to the Business profile manager and
            reviews you&rsquo;ve posted on{' '}
            <Anchor
              href="https://www.localsearch.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              localsearch.com.au
            </Anchor>
            . If you&rsquo;d like to pause your user-exclusive features, you can
            always log out.
          </Paragraph>
          <Paragraph>
            If you would like to discuss your subscription please contact head
            office on <Anchor href="tel:1300360867">1300 360 867</Anchor>.
          </Paragraph>
          {paidPackage ? (
            <Button
              width="auto"
              padding="0 25px"
              onClick={() => setShowConfirm(true)}
              rounded
              border
            >
              Lodge request
            </Button>
          ) : (
            <Anchor
              display="inline-block"
              border="1px solid #9b9b9b"
              padding="11px 22px"
              borderRadius="30px"
              href={emailHref}
              color="text400"
              fontWeight="600"
              _hover={{
                border: '1px solid #C5D4FF',
              }}
            >
              Lodge request
            </Anchor>
          )}
        </Panel>
        <Panel flex="1" minWidth="300px">
          <Container marginBottom="15px">
            <Wave />
          </Container>
          <H2>What happens when I log out of my account?</H2>
          <Paragraph marginBottom="20px">
            By logging out of your{' '}
            <Anchor target="_blank" href="https://www.localsearch.com.au">
              localsearch.com.au
            </Anchor>{' '}
            account, you&apos;ll be able to pause notifications from the app.
            You will need to log in to your account again to update reviews
            you&apos;ve posted, auto-fill forms and more.
          </Paragraph>
          <Button
            width="auto"
            padding="0 25px"
            onClick={auth.logout}
            rounded
            border
          >
            Log out of my account
          </Button>
        </Panel>
      </Container>
      <Dialog
        mode={mobile ? 'mobile' : 'desktop'}
        padding={mobile ? '30px' : '35px 35px 25px 35px'}
        display="flex"
        render={({ children }) => (
          <Container flex="1" position="relative">
            {children}
          </Container>
        )}
        clickOutside
        onClose={() => setShowConfirm(false)}
        show={showConfirm}
      >
        <CloseButton
          onClick={() => setShowConfirm(false)}
          position="absolute"
          right="-20px"
          top="-20px"
        />
        <H2 marginBottom="12px">Are you sure?</H2>
        <Paragraph marginBottom="20px">
          Deleting your user profile will delete all activity and remove access
          to the Business Profile Manager. If you wish to discuss your
          subscription instead, please contact head office on{' '}
          <Anchor href="tel:1300360867">1300 360 867</Anchor>.
        </Paragraph>
        <Anchor
          display="inline-block"
          backgroundColor="primary"
          padding="12px 23px"
          borderRadius="30px"
          href={emailHref}
          color="white"
          fontWeight="600"
          onClick={() => setShowConfirm(false)}
        >
          Continue
        </Anchor>
      </Dialog>
    </>
  );
};

export default HowToManage;
