import React from 'react';

const Face = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#285AFF"
            d="M24.3 0C10.907 0 0 10.907 0 24.3s10.907 24.3 24.3 24.3 24.3-10.907 24.3-24.3S37.693 0 24.3 0zm0 46.44c-12.205 0-22.14-9.935-22.14-22.14S12.095 2.16 24.3 2.16s22.14 9.935 22.14 22.14-9.935 22.14-22.14 22.14z"
            transform="translate(-122 -236) translate(122 236)"
          />
          <path
            fill="#285AFF"
            d="M11.555 24.515l4.105-4.105 4.105 4.105c.215.217.485.325.755.325s.54-.108.755-.325c.432-.433.432-1.08 0-1.513L17.172 18.9l4.105-4.105c.432-.433.432-1.08 0-1.513-.432-.432-1.08-.432-1.512 0l-4.105 4.105-4.105-4.105c-.433-.432-1.08-.432-1.513 0-.432.433-.432 1.08 0 1.513l4.105 4.105-4.105 4.105c-.432.432-.432 1.08 0 1.512.218.215.488.323.758.323s.54-.108.755-.325zM38.555 13.285c-.433-.432-1.08-.432-1.513 0l-4.102 4.103-4.105-4.105c-.433-.432-1.08-.432-1.513 0-.432.432-.432 1.08 0 1.512l4.105 4.105-4.105 4.105c-.432.433-.432 1.08 0 1.513.218.215.488.322.758.322s.54-.107.755-.325l4.105-4.104 4.105 4.104c.215.218.485.325.755.325s.54-.107.755-.325c.432-.432.432-1.08 0-1.512L34.452 18.9l4.105-4.105c.43-.43.43-1.08-.002-1.51z"
            transform="translate(-122 -236) translate(122 236)"
          />
          <path
            fill="#F16159"
            d="M36.72 29.7H11.88c-.595 0-1.08.485-1.08 1.08 0 .595.485 1.08 1.08 1.08h14.58v3.725c0 2.43 1.835 4.375 4.05 4.375h.54c2.215 0 4.05-1.945 4.05-4.375V31.86h1.62c.595 0 1.08-.485 1.08-1.08 0-.595-.485-1.08-1.08-1.08zm-3.78 5.885c0 1.187-.865 2.215-1.89 2.215h-.54c-1.025 0-1.89-1.025-1.89-2.215V31.86h4.32v3.725z"
            transform="translate(-122 -236) translate(122 236)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Face;
