import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as theme from 'ls-common-client/src/themes/default';
import { AppContext } from '../context';
import Layout from './Layout';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.text.normal};
    height: 1px;
    min-height: 100%;
    background-color: ${props => props.theme.background.normal};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100%;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  button {
    font-family: Circular;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <AppContext.Provider>
      <React.Fragment>
        <GlobalStyle />
        <Layout />
      </React.Fragment>
    </AppContext.Provider>
  </ThemeProvider>
);

export default App;
