import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';

const Paragraph = ({ children, ...props }) => (
  <Text
    marginBottom="30px"
    fontSize="16px"
    lineHeight="1.4"
    display="block"
    color="text400"
    {...props}
  >
    {children}
  </Text>
);

Paragraph.propTypes = {
  children: PropTypes.node,
};

Paragraph.defaultProps = {
  children: null,
};

export default Paragraph;
