import React from 'react';

const Wave = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="47"
    viewBox="0 0 55 47"
  >
    <g fill="none" fillRule="evenodd">
      <g strokeWidth=".5">
        <g>
          <path
            fill="#40A499"
            stroke="#40A499"
            d="M47.95 21.1c-.12-.48-.662-.78-1.14-.6-.48.12-.78.661-.6 1.14.061.3 1.92 7.019-3.96 13.86-.3.362-.3.962.06 1.262.18.12.36.239.6.239s.48-.12.661-.3c6.598-7.68 4.437-15.3 4.378-15.6h0zM52.75 20.078c-.12-.48-.662-.72-1.14-.6-.48.12-.78.661-.6 1.14 0 .119 2.761 9.719-5.52 19.2-.3.36-.3.96.061 1.26.18.12.361.24.6.24.24 0 .48-.12.661-.3 8.937-10.26 6.057-20.518 5.937-20.94h0zM13.207 7.42c.42-.3.48-.84.18-1.261-.3-.42-.838-.48-1.26-.18-.3.18-6.961 5.1-6.72 15.538 0 .48.42.9.9.9.48 0 .9-.42.9-.9-.18-9.478 5.761-13.917 6-14.097h0zM10.088 3.52c.42-.3.48-.84.18-1.261-.3-.42-.839-.48-1.26-.18-.36.24-9.3 6.84-9 20.94 0 .481.419.9.9.9.48 0 .9-.419.9-.9-.3-13.14 8.16-19.44 8.28-19.5h0z"
            transform="translate(-771 -236) translate(772 237)"
          />
          <path
            fill="#285AFF"
            stroke="#285AFF"
            d="M43.568 16.24c.24-1.02.061-2.04-.539-2.94-.539-.9-1.439-1.5-2.46-1.739-.84-.18-1.74-.12-2.52.3l.539-2.46c.48-2.1-.839-4.2-2.939-4.681-.9-.18-1.74-.061-2.52.3l.061-.24c.24-1.019.061-2.038-.539-2.938-.539-.9-1.439-1.5-2.46-1.74-1.02-.238-2.04-.06-2.94.54-.9.539-1.5 1.439-1.739 2.46l-.239 1.2c-.539-.66-1.26-1.138-2.16-1.319-2.1-.48-4.2.84-4.681 3l-1.32 5.94c-2.039-.42-4.08.96-4.56 3l-2.52 11.338c-1.8 8.22 3.36 16.38 11.58 18.239h.061c3.961.9 8.1.18 11.52-2.04 3.42-2.219 5.82-5.58 6.72-9.6l3.655-16.62zm-5.16 16.26c-1.62 7.44-9 12.12-16.44 10.5h-.06c-7.38-1.68-12.061-9-10.439-16.438l2.52-11.34c.3-1.319 1.56-2.1 2.88-1.8.18.062.3.062.42.12.419.18.78.48 1.019.9.361.54.48 1.2.3 1.8l-1.559 7.498v.485s0 .061.061.061c0 0 0 .061.061.061l.061.061.061.06.061.062c2.88 2.039 4.261 5.639 3.54 9-.062.42.18.839.6.9.419.06.838-.18.9-.6.838-3.78-.662-7.861-3.72-10.261l1.56-7.08c.24-1.02.062-2.04-.538-2.94-.3-.48-.72-.9-1.14-1.2l1.38-6.18c.3-1.32 1.562-2.1 2.881-1.8 1.261.3 2.1 1.56 1.8 2.88l-2.52 11.339c-.119.42.181.839.6.9.42.06.84-.18.9-.6l3.481-15.54c.12-.6.54-1.138 1.08-1.5.54-.36 1.2-.48 1.8-.3.6.12 1.14.54 1.5 1.081.362.54.481 1.2.3 1.8l-3.78 16.74c-.12.419.18.838.6.9.42.06.84-.181.9-.6l3.061-13.5c.3-1.32 1.561-2.1 2.88-1.8 1.32.3 2.1 1.56 1.8 2.88l-1.2 5.46L33.5 25.85c-.12.419.18.78.6.9.42.06.84-.181.9-.6l2.389-11.23c.12-.6.539-1.139 1.08-1.5.54-.36 1.2-.48 1.8-.3.6.12 1.14.54 1.5 1.08.361.54.48 1.2.3 1.8L38.408 32.5z"
            transform="translate(-771 -236) translate(772 237)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Wave;
