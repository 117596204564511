import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const toPhoneE164 = number => {
  if (!number) return '';
  const phoneUtil = PhoneNumberUtil.getInstance();
  const parsed = phoneUtil.parseAndKeepRawInput(number || '', 'AU');

  return phoneUtil.format(parsed, PhoneNumberFormat.E164);
};

const fromPhoneE164 = number => {
  if (!number) return '';
  if (!number.includes('+61')) return number;
  return number.includes('+614')
    ? number.replace('+614', '04')
    : number.replace('+61', '');
};

export { toPhoneE164, fromPhoneE164 };
