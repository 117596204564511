import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Icon from 'ls-common-client/src/components/Icon';
import { AppContext } from '../../context';

const StyledAnchorLink = styled(AnchorLink)`
  display: flex;
  align-items: center;
`;

const BackBar = () => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <Container
      display="flex"
      justifyContent="flex-start"
      padding={mobile ? '15px 10px' : '35px 10px'}
    >
      <StyledAnchorLink color="normal" fontSize="normal" to="/">
        <Icon
          marginBottom="-7px"
          iconSize="large"
          className="ls-icon icon-generalarrowwlarge"
        />
        Back
      </StyledAnchorLink>
    </Container>
  );
};

export default BackBar;
