import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';

const H1 = ({ children, ...props }) => (
  <Text
    marginBottom="18px"
    fontSize="22px"
    fontWeight="600"
    lineHeight="1.2"
    display="block"
    {...props}
  >
    {children}
  </Text>
);

H1.propTypes = {
  children: PropTypes.node,
};

H1.defaultProps = {
  children: null,
};

export default H1;
