import React, { useContext, useState } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import { AppContext } from '../../../context';
import { Panel, SuccessNotification } from '../../common';
import Profile from './Profile';
import Account from './Account';
import Help from './Help';

const Home = () => {
  const {
    user: { user },
    media: { mobile },
  } = useContext(AppContext.Context);

  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <Container
        display="flex"
        flexWrap="wrap"
        padding={mobile ? '35px 20px 10px 20px' : '45px 20px 20px 20px'}
      >
        <Text
          lineHeight="1"
          color="normal"
          fontSize={mobile ? '26px' : '30px'}
          fontWeight="600"
          display="block"
          marginBottom="8px"
          marginRight="7px"
        >
          Welcome back,
        </Text>
        <Text
          lineHeight="1"
          color="normal"
          fontSize={mobile ? '26px' : '30px'}
          display="block"
        >
          {user.displayName}
        </Text>
      </Container>
      <Container padding="10px" display="flex" flexWrap="wrap">
        <Panel flex="3" minWidth="280px">
          <Profile setShowSuccess={setShowSuccess} />
        </Panel>
        <Panel flex="5.3" minWidth={mobile ? '280px' : '400px'}>
          <Account setShowSuccess={setShowSuccess} />
        </Panel>
        <Container
          display="flex"
          flexDirection="column"
          minWidth="220px"
          flex="2.5"
        >
          <Help />
        </Container>
      </Container>
      <SuccessNotification
        show={showSuccess}
        onClose={() => setShowSuccess(false)}
      />
    </>
  );
};

export default Home;
